import React, { useEffect } from "react";
import { NextPage } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { JsonLd } from "react-schemaorg";
import { LocalBusiness } from "schema-dts";

import getRegionComplianceRules from "api/requests/consumerApi/getRegionComplianceRules";
import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import { getDispensary } from "api/requests/getDispensary";
import { getDispensaryDeals } from "api/requests/getDispensaryDeals";
import getMenuItems, { GetMenuItemsReturn } from "api/requests/getMenuItems";
import { DISPENSARY_PAGES } from "constants/dispensary";
import { MenuProvider } from "context/MenuContext";
import { Deal } from "custom-types/Deals";
import { Dispensary, RetailType } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import useDomain from "hooks/useDomain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import {
  dispensaryJsonSchema,
  retailerMainMetaData,
} from "lib/dispensaryMetadata";
import { COMPLIANCE_RULESET_LOAD } from "redux/reducers/complianceRules";
import {
  getIsOrderingAllowed,
  getIsThirdPartyOrderingProhibited,
} from "redux/selectors/complianceRules";
import { getDomainCountryCode } from "redux/selectors/config";
import { getCoordinates } from "redux/selectors/location";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { getDispensaryUrl } from "utils/dispensaryUrls";
import logError from "utils/logError";
import { getMenuTypeCookie } from "utils/menuTypeUtils";
import useTrackDispensaryPageViewSplitEvent from "utils/split/useTrackDispensaryPageViewSplitEvent";
import { trackUserHistory } from "utils/userHistory";
import { validateDispensary } from "utils/validateDispensary";

import DispensaryBreadcrumbs from "components/Dispensary/DispensaryBreadcrumbs";
import DispensaryHeader from "components/Dispensary/DispensaryHeader";
import DispensaryTabs from "components/Dispensary/DispensaryTabs";
import MenuNotAvailable from "components/Dispensary/MenuNotAvailable";
import NonCompliantMenu from "components/Dispensary/NonCompliantMenu";
import PaidTierMenu from "components/Dispensary/PaidTierMenu";
import UberEgress from "components/UberEgress";

import MetaTags from "../../components/MetaTags";

const NearbyStores = dynamic(
  () => import("components/Dispensary/NearbyStores"),
);
const AboutDispensary = dynamic(
  () => import("components/Dispensary/AboutDispensary"),
);
const ReviewConfirmationModal = dynamic(
  () => import("components/Dispensary/ReviewsHeader/ReviewConfirmationModal"),
);

type MainPageProps = {
  brandPlacement: Placement | null;
  deals: Deal[];
  dispensary: Dispensary;
  menuData: GetMenuItemsReturn;
  staffPicks: MenuItem[];
};

const MainPage: NextPage<MainPageProps> = ({
  brandPlacement,
  deals,
  dispensary,
  menuData,
  staffPicks,
}) => {
  const {
    id,
    city,
    coverPhotoUrl,
    featureTier,
    hasMenuItems,
    logoUrl,
    name,
    retailType,
    slug,
    state,
    uberEatsStoreUrl,
    tags,
  } = dispensary;
  const baseUrl = useDomain();

  const isInfoTier = featureTier === "info";
  const isDispensaryRetailType = retailType === RetailType.Dispensary;

  useTrackDispensaryPageViewSplitEvent({ page: "main", tier: featureTier });

  useEffect(() => {
    trackUserHistory({
      resourceId: id,
      slug,
      type: "dispensary",
    });
  }, [id]);

  const router = useRouter();
  const countryCode = useDomainCountryCode();
  const thirdPartyOrderingProhibited = useSelector(
    getIsThirdPartyOrderingProhibited,
  );

  let isLeaflyOrderingAllowed = useSelector(getIsOrderingAllowed);
  // * Only "dispensary" types are restricted, since clinic and cbd-store types
  // * typically don't sell restricted items.
  if (!isDispensaryRetailType) {
    isLeaflyOrderingAllowed = true;
  }

  const showConfirmationModal = !!router.query?.show_confirmation_modal;
  const showFavoriteDispensaryMessage = !!router.query?.show_favorite_message;

  const ssoMenuRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
          tab: "menu",
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoMenuRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const ssoDispensaryRedirect = async () => {
    try {
      await router.push(
        getDispensaryUrl({
          countryCode,
          slug,
        }),
      );
    } catch (err) {
      logError(
        "Error redirecting in ssoDispensaryRedirect on /dispensary-info/[slug]",
      );
    }
  };

  const { description, image, title } = retailerMainMetaData(
    city,
    countryCode,
    coverPhotoUrl,
    logoUrl,
    name,
    retailType as RetailType,
    state,
  );

  const isDualLicense = isDispensaryDualLicense(tags);

  return (
    <MenuProvider
      isDualLicenseDispensary={isDualLicense}
      initialData={menuData}
      staffPicks={staffPicks}
    >
      <MetaTags
        canonicalParams={[]}
        description={description}
        image={image}
        title={title}
      />
      <DispensaryHeader dispensary={dispensary} isMainPage={true} />
      <JsonLd<LocalBusiness>
        item={dispensaryJsonSchema(dispensary, baseUrl, dispensary.country)}
      />
      {thirdPartyOrderingProhibited && isDispensaryRetailType && (
        <NonCompliantMenu
          dispensaryId={dispensary.id}
          dispensaryName={dispensary.name}
          dispensaryWebsite={dispensary.website}
          hideCTA={isInfoTier}
          thirdPartyOrderingProhibited={thirdPartyOrderingProhibited}
          isLeaflyOrderingAllowed={isLeaflyOrderingAllowed}
        />
      )}
      <DispensaryTabs
        activeTab={DISPENSARY_PAGES.main}
        dispensary={dispensary}
      />
      {uberEatsStoreUrl && (
        <UberEgress
          url={uberEatsStoreUrl}
          className="container mt-lg md:hidden"
          dispensaryId={dispensary.id}
        />
      )}
      <div className="flex flex-col gap-xxl">
        {!isInfoTier && hasMenuItems ? (
          <PaidTierMenu
            dispensary={dispensary}
            brandPlacement={brandPlacement}
            deals={deals}
          />
        ) : (
          <>
            <MenuNotAvailable
              dispensaryFeatureTier={dispensary.featureTier}
              dispensaryId={dispensary.id}
              dispensaryName={dispensary.name}
              dispensaryRetailType={dispensary.retailType}
            />
            {isInfoTier && (
              <NearbyStores
                dispensaryId={dispensary.id}
                locations={dispensary.locations}
                locationPaths={dispensary.locationPaths}
                className="container mt-xl md:mt-[40px] mb-lg"
              />
            )}
          </>
        )}
        <AboutDispensary dispensary={dispensary} />
        {showConfirmationModal && (
          <ReviewConfirmationModal
            dispensaryId={dispensary.id}
            favorite={showFavoriteDispensaryMessage}
            ssoMenuRedirect={ssoMenuRedirect}
            ssoDispensaryRedirect={ssoDispensaryRedirect}
          />
        )}
        <DispensaryBreadcrumbs dispensary={dispensary} />
      </div>
    </MenuProvider>
  );
};

MainPage.getInitialProps = async (context) => {
  const { req, res, query, store } = context;
  const { slug, menu_type } = query;

  const filters = query || {};

  const coordinates = getCoordinates(store.getState());
  const countryCode = getDomainCountryCode(store.getState());

  try {
    const dispensary = await getDispensary(slug as string, coordinates);

    const { redirect } = await validateDispensary({
      countryCode,
      dispensary,
      query,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- temporarily using non-null assertion before refactoring validateDispensary
      req: req!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- temporarily using non-null assertion before refactoring validateDispensary
      res: res!,
    });

    if (redirect) {
      return;
    }

    const userMedRecPreference = menu_type
      ? Array.isArray(menu_type)
        ? String(menu_type)
        : menu_type
      : getMenuTypeCookie(req);
    const isDualLicenseDispensary = isDispensaryDualLicense(
      dispensary?.tags || [],
    );

    const menuItemsCall = getMenuItems(slug as string, {
      ...filters,
      enableNewFilters: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const complianceCall = getRegionComplianceRules(
      dispensary?.state,
      dispensary?.country,
    );

    const brandPlacementCall = getBrandPlacement(
      (dispensary as Dispensary).slug,
      {
        menuType: isDualLicenseDispensary ? userMedRecPreference : undefined,
        strategies: ["lite-amplification", "menu-merchandising"],
      },
    );

    const dealsCall = getDispensaryDeals((dispensary as Dispensary).slug);

    const staffPicksCall = getMenuItems(slug as string, {
      is_staff_pick: true,
      menu_type: isDualLicenseDispensary ? userMedRecPreference : undefined,
    });

    const [
      menuData,
      complianceRuleset,
      brandPlacement,
      { data: deals },
      { menuItems: staffPicks },
    ] = await Promise.all([
      menuItemsCall,
      complianceCall,
      brandPlacementCall,
      dealsCall,
      staffPicksCall,
    ]);

    if (complianceRuleset) {
      store.dispatch({
        complianceRuleset,
        type: COMPLIANCE_RULESET_LOAD,
      });
    }

    return {
      brandPlacement,
      deals,
      dispensary,
      menuData,
      staffPicks,
    };
  } catch (err) {
    logError(err.message || "Unknown error", {
      functionName: "/dispensary-info/[slug] getInitialProps",
    });

    return err;
  }
};

export default MainPage;
